import {LoadingDialog} from '@continental/components/dialogs/loading-dialog/loading-dialog.component';
import {Injectable, Optional} from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogState} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  dialogRef: MatDialogRef<any>;
  opened = false;

  constructor(@Optional() public dialog: MatDialog) {}

  private open(title: string, message: string, width: any) {
    this.dialogRef = this.dialog.open(LoadingDialog, {
      width,
      disableClose: true,
      data: { title, message},
    });
  }

  show(title: string, message: string, width = '640px'): void {

    let openAfterClose = false;

    if (this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) {
      openAfterClose = true;
      this.hide();
    }
    if (!openAfterClose) {
      this.open(title, message, width);
    }

    this.dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (openAfterClose) {
        this.open(title, message, width);
      }
    });
  }

  hide() {

      this.dialogRef.close();
  }
}
