import { LoadingDialogData } from './loading-dialog.model';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'dialog-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})

export class LoadingDialog implements OnInit {

  constructor(
    @Optional() public dialogRef: MatDialogRef<LoadingDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: LoadingDialogData) {}

  ngOnInit(): void {
  }

}
