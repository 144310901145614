<div id="toolbar">
  <div id="toolbar-container">
    <div id="logo">
      <a routerLink="['/']">
        <img src="assets/logo/continental-logo.png" alt="Continental"/>
      </a>
    </div>

    <div id="main" fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign="space-between center">

      <h1>Credit score</h1>

      <div fxHide.lt-md fxShow.gt-sm>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
          <div id="navigation" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <a mat-button routerLink="/summary" routerLinkActive #summary="routerLinkActive"
               [color]="summary.isActive ? 'primary': ''">Riepilogo</a>
            <a mat-button routerLink="/prospect" routerLinkActive #prospect="routerLinkActive"
               [color]="prospect.isActive ? 'primary': ''">Prospect</a>
            <a mat-button routerLink="/underwriting" routerLinkActive #underwriting="routerLinkActive"
               [color]="underwriting.isActive ? 'primary': ''">Underwriting</a>
            <a mat-button routerLink="/clients" routerLinkActive #clients="routerLinkActive"
               [color]="clients.isActive ? 'primary': ''">Renewal</a>
            <a mat-button routerLink="/monitoring" routerLinkActive #monitoring="routerLinkActive"
               [color]="monitoring.isActive ? 'primary': ''">Monitoring</a>
          </div>

          <div *ngIf="currentUser">
            <button mat-flat-button color="primary" [matMenuTriggerFor]="menuUser" aria-label="User menu">
              <mat-icon>person</mat-icon>
              {{currentUser.firstName | slice:0:1}}. {{currentUser.lastName}}
            </button>
          </div>
          <mat-menu #menuUser="matMenu">
            <mat-list role="list" *ngIf="currentUser">
              <mat-list-item role="listitem">
                <mat-icon mat-list-icon>account_circle</mat-icon>
                <div mat-line>{{currentUser.username}}</div>
              </mat-list-item>
              <mat-list-item role="listitem">
                <mat-icon mat-list-icon>email</mat-icon>
                <div mat-line>{{currentUser.email}}</div>
              </mat-list-item>
              <mat-list-item role="listitem" *ngIf="currentUser.actualRole">
                <mat-icon mat-list-icon>star_rate</mat-icon>
                <div mat-line>{{roleDisplayName}}</div>
              </mat-list-item>
            </mat-list>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu>
        </div>
      </div>


      <button fxShow.lt-ms fxHide.gt-sm mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>


    <app-csv-widget></app-csv-widget>

  </div>
</div>

<mat-menu #menu="matMenu">
  <a mat-menu-item routerLink="/summary">Riepilogo</a>
  <a mat-menu-item routerLink="/prospect">Prospect</a>
  <a mat-menu-item routerLink="/underwriting">Underwriting</a>
  <a mat-menu-item routerLink="/clients">Clienti</a>
  <a mat-menu-item routerLink="/monitoring">Monitoring</a>
</mat-menu>
