import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LoadingImageModule } from './../loading-image/loading-image.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingDialog } from './loading-dialog/loading-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ErrorDialog } from './error-dialog/error-dialog.component';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog.component';
import { InfoDialog } from './info-dialog/info-dialog.component';
import {ContentDialogComponent} from './content-dialog/content-dialog.component';


@NgModule({
  declarations: [LoadingDialog, InfoDialog, ErrorDialog, ConfirmDialog, ContentDialogComponent],
  exports: [LoadingDialog, InfoDialog, ErrorDialog, ConfirmDialog, ContentDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    LoadingImageModule,
  ]
})
export class DialogsModule { }
