<ngx-datatable
  class="list-table"
  [rows]="rows"
  [columns]="columns"
  rowHeight="auto"
  [columnMode]="ColumnMode.force"
  [scrollbarH]="true"
  [selected]="selected"
  [selectionType]="SelectionType.checkbox"
  [displayCheck]="displayCheck"
  (select)="onSelect($event)"
>
  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
    [headerCheckboxable]="true"
    [checkboxable]="downloadingCount === 0"
  ></ngx-datatable-column>
  <ngx-datatable-column
    name="type" cellClass="type-cell"
    [width]="40"
    [canAutoResize]="false"
    [resizeable]="false">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <mat-icon [matTooltip]="value">insert_drive_file</mat-icon>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="name">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <div class="title">{{value}}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="size"
    cellClass="size-cell"
    [width]="60"
    [canAutoResize]="false"
    [resizeable]="false">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <div class="title">{{value | filesize}}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="action"
    [frozenRight]="true"
    [canAutoResize]="false"
    [resizeable]="false"
    cellClass="action-cell" >
    <ng-template let-value="value" let-index="rowIndex" ngx-datatable-cell-template>
      <button *ngIf="!value.isDownloading" mat-flat-button color="primary" (click)="download(value, index)">Download</button>
      <mat-progress-bar *ngIf="value.isDownloading" mode="indeterminate" color="primary"></mat-progress-bar>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
<div fxLayout="row" class="p-16 p-20 footer" fxLayoutAlign="end center" fxLayoutGap="16px">
  <button mat-flat-button [disabled]="!selected?.length || downloadingCount !== 0" (click)="remove()" class="bad">Elimina</button>
</div>
