import {RejectionInput} from './../models/customer';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import gql from 'graphql-tag';
import {Classification, Underwriting, UnderwritingList, UnderwritingStatus} from '@creditscore/graphql-models';
import {Apollo} from 'apollo-angular';
import {Injectable, Optional} from '@angular/core';
import {ApolloQueryResult, FetchResult} from '@apollo/client/core';
import {ApprovalInput} from '@continental/models/customer';
import {Field, Order, SortingField} from '@creditscore/graphql-models/lib/graphql.types';

@Injectable({
  providedIn: 'root'
})
export class UnderwritingService {

  underwritings: Underwriting[];
  underwriting: Underwriting;

  constructor(@Optional() private apollo: Apollo) {
  }

  private getUnderWritingBody() {
    return `{
              id,
              name,
              classification,
              credit {
                id,
                amount,
                suggestedAmount,
                status
              }
              vatNumber,
              status,
              address {
                street,
                city,
                province,
                postalCode
              },
              salesContact {
                id,
                emails,
                firstName,
                lastName,
                phoneNumbers
              },
              scoreCard {
                score,
                notGo,
                lastUpdate,
                variables {
                  name,
                  value,
                  score,
                  computed,
                  origin,
                }
              },
              approvalLevels {
                threshold,
                title,
                roles,
                needsAttachment,
                state{
                  ... on Approval {
                    approvalDate,
                    approvalReason: reason,
                    user {
                      id,
                      emails,
                      firstName,
                      lastName,
                    },
                    attachment {
                      uri,
                      name,
                      type,
                      size,
                    },
                    credit {
                      amount
                    },
                  },
                  ... on Rejection {
                    rejectionDate,
                    rejectionReason: reason,
                    user {
                      id,
                      emails,
                      firstName,
                      lastName,
                    },
                      credit {
                      amount
                    }
                  }
                }
              },
          }`;
  }

  create(vatNumber: string): Observable<FetchResult<{ createUnderwriting: Underwriting }>> {
    return this.apollo.mutate<{ createUnderwriting: Underwriting }>({
      mutation: gql`
        mutation {
          createUnderwriting(vatNumber: "${vatNumber}") {
            id,
            name,
            status,
          }
        }
      `
    });
  }

  getList(
    filter = '',
    offset = 0,
    limit = 10,
    viewAll = true,
    classifications: Classification[] = [],
    underwritingStatus: UnderwritingStatus[] = [],
    sortingFields: SortingField[] = [{field: Field.NAME, order: Order.ASC}]):
    Observable<ApolloQueryResult<{ getUnderwritingList: UnderwritingList }>> {
    return this.apollo.watchQuery<{ getUnderwritingList: UnderwritingList }>({
      query: gql`
        query underwritingList ($fields: [SortingField!]!, $classifications: [Classification!], $underwritingStatus: [UnderwritingStatus!]){
          getUnderwritingList (query: {
            filter: {
              text: "${filter}",
              offset: ${offset},
              limit: ${limit},
              viewAll: ${viewAll},
              classifications: $classifications,
              underwritingStatus: $underwritingStatus,
            },
            sorting: {
              fields: $fields,
            }
          }) {
            totalCount,
            offset,
            limit,
            underwritings ${this.getUnderWritingBody()}
          }
        }
      `,
      variables: {
        fields: sortingFields,
        classifications,
        underwritingStatus,
      },
      errorPolicy: 'all'
    })
    .valueChanges.pipe(
      tap(res => {

        if (res.data && res.data.getUnderwritingList && res.data.getUnderwritingList.underwritings) {
          this.underwritings = res.data.getUnderwritingList.underwritings;
        } else {
          this.underwritings = [];
        }
      }),
      catchError((err, caught) => {
        // whatever you want to handle error
        if (err.graphQLErrors) {
          err.graphQLErrors.forEach(e => {
            console.log(e);
          });
        }
        if (err.networkError) {
          console.log(err);
        }
        // default return
        return caught;
      }));
  }

  getById(id: string):
    Observable<ApolloQueryResult<{ getUnderwritingById: Underwriting }>> {
    /* return new Observable(observer => {
      observer.next({
        data: {
          getById: UNDERWRITINGS[0]
        },
        loading: false,
        networkStatus: 200,
      });

      observer.complete();
    }); */

    return this.apollo.watchQuery<{ getUnderwritingById: Underwriting }>({
      query: gql`
        query getUnderwriting {
          getUnderwritingById (underwritingId: ${id}) ${this.getUnderWritingBody()}
        }
      `
    })
    .valueChanges.pipe(tap(res => {

      if (res.data && res.data.getUnderwritingById) {
        this.underwriting = res.data.getUnderwritingById;
      } else {
        this.underwritings = undefined;
      }
    }));
  }

  reject(rejection: RejectionInput): Observable<FetchResult<Underwriting>> {
    return this.apollo.mutate<Underwriting>({
      mutation: gql`
        mutation {
          rejectUnderwriting(
            vatNumber: "${rejection.vatNumber}",
            rejection: {
              level: ${rejection.level},
              reason: "${rejection.reason}"
            }
          ) ${this.getUnderWritingBody()}
        }
      `
    });
  }


  approveUnderwriting(data: ApprovalInput): Observable<FetchResult<Underwriting>> {
    return this.apollo.mutate<Underwriting>({
      mutation: gql`
        mutation approveUnderwriting($reason: String, $attachment: CustomerAttachmentInput){
          approveUnderwriting(
            vatNumber: "${data.vatNumber}",
            approval: {
              renewalDate: ${data.renewalDate.getTime()},
              credit: ${data.credit},
              level: ${data.level},
              reason: $reason,
              attachment: $attachment
            }
          ) {id, name}
        }
      `,
      variables: {
        reason: data.reason,
        attachment: data.attachment,
      },
    });
  }
}
