import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
  NextLink,
  Operation,
  split,
  ServerError
} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {KeycloakService} from 'keycloak-angular';
import {Observable} from '@apollo/client/utilities';
import {onError} from '@apollo/client/link/error';
import {ErrorHandlerService} from '@continental/services/error-handler.service';
import {getMainDefinition} from 'apollo-utilities';
import {OperationDefinitionNode} from 'graphql';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import {WebSocketLink} from '@apollo/client/link/ws';
import {WShelperService} from '../@continental/services/wshelper.service';


function _window() {
  // return the global native browser window object
  return window as { [key: string]: any };
}

function keycloakTokenAsObservable(keycloakService: KeycloakService): Observable<string> {
  return new Observable((observer) => {
    keycloakService.getToken().then(token => {
      observer.next(token);
      observer.complete();
    }, error => {
      observer.error(error);
    }).catch(error => {
      observer.error(error);
    });
  });
}


const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export function createApollo(
  httpLink: HttpLink,
  keycloakService: KeycloakService,
  errorService: ErrorHandlerService,
  wsHelperService: WShelperService): ApolloClientOptions<any> {
  const authLink = new ApolloLink((operation: Operation, forward: NextLink) => {
      return keycloakTokenAsObservable(keycloakService).flatMap(
        token => {
          // Use the setContext method to set the JWT token as Authorization HTTP header
          operation.setContext({
            headers: {
              authorization: token ? `Bearer ${token}` : ''
            }
          });
          return forward(operation);
        });
    }
  );
  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (networkError) {
      if (typeof networkError === 'boolean') {
        console.log('Network error');
        keycloakService.logout();
      } else {
        if ('statusCode' in networkError) {
          switch (networkError.statusCode) {
            case 401:
              console.log('Needs to be authenticated');
              keycloakService.logout();
              break;
          }
        }
      }
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
      errorService.show('Ooops!', graphQLErrors);
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const subscriptionClient = new SubscriptionClient(_window().env.gatewayWSUrl, {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      return new Promise((resolve, reject) => {
        keycloakTokenAsObservable(keycloakService).subscribe(token => {
          token ? resolve({authorization: `Bearer ${token}`}) : reject('No JWT token found!');
        });
      });
    }
  });
  subscriptionClient.onConnected(() => {
    console.log('WS onConnected');
    wsHelperService.setWsConnectionState(true);
  });
  subscriptionClient.onReconnected(() => {
    console.log('WS onReconnected');
    wsHelperService.setWsConnectionState(true);
  });

  subscriptionClient.onReconnecting(() => {
    console.log('WS onReconnecting');
    wsHelperService.setWsConnectionState(false);
  });

  subscriptionClient.onDisconnected(() => {
    console.log('WS onDisconnected');
    wsHelperService.setWsConnectionState(false);
  });

  subscriptionClient.onError(() => {
    console.log('WS onError');
  });

  const wsLink = new WebSocketLink(subscriptionClient);

  // const wsLink = new WebSocketLink({
  //   uri: _window().env.gatewayWSUrl,
  //   options: {
  //     lazy: true,
  //     reconnect: true,
  //     connectionParams: async () => {
  //       return new Promise((resolve, reject) => {
  //         keycloakTokenAsObservable(keycloakService).subscribe(token => {
  //           token ? resolve({authorization: `Bearer ${token}`}) : reject('No JWT token found!');
  //         });
  //       });
  //     }
  //   }
  // });

  const splitLink = split(
    // split based on operation type
    ({query}) => {
      const {kind, operation} = getMainDefinition(query) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    ApolloLink.from([
      errorLink,
      authLink,
      httpLink.create({uri: _window().env.gatewayUrl}),
    ])
  );

  return {
    link: splitLink,
    cache: new InMemoryCache(),
    defaultOptions,
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, KeycloakService, ErrorHandlerService, WShelperService],
    },
  ],
})
export class GraphQLModule {
}
