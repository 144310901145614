import { ToolbarModule } from './toolbar/toolbar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ToolbarModule,
  ],
  exports: [
    ToolbarModule
  ]
})
export class LayoutModule { }
