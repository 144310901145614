import {Observable} from 'rxjs';
import {
  ChartItem,
  Classification,
  Credit,
  CreditStatus,
  Customer,
  CustomerList,
  CustomerRequirements,
  CustomerRequirementsInput,
  CustomerStatus,
  CustomerVariableOrigin,
  Field,
  InsolvencyZone,
  Order,
  PaymentMethod,
  SortingField,
  TimeRange
} from '@creditscore/graphql-models';

import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';
import {Injectable, Optional} from '@angular/core';
import {ApolloQueryResult, FetchResult} from '@apollo/client/core';
import {ApprovalInput, RejectionInput} from '../models/customer';
import {catchError, map, tap} from 'rxjs/operators';
import {
  BulkSummary,
  CustomerAttachment,
  CustomerAttachmentInput,
  MassiveRenewalExecution,
  MassiveRenewalLevel,
  MassiveRenewalProgress,
  MutationEmptyResponse
} from '@creditscore/graphql-models/lib/graphql.types';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  customers: Customer[] = [{
    id: '1',
    name: 'Jake Nikes',
    classification: Classification.RETAIL,
    status: CustomerStatus.LEGAL_DISPUTE,
    attachments: [],
    vatNumber: 'kLw20J2Jqsa21jqajwk',
    payerCode: 'ldsjvnda3253462fnv',
    requirements: {
      yearlyTargetRevenue: 100000,
      monthlyPeakRevenue: 1000,
      paymentTerms: 123,
      paymentMethod: PaymentMethod.RIBA,
    },
    address: {
      street: 'Another Street',
      city: 'NY',
      province: 'KE',
      postalCode: '920012',
      id: '0',
      region: '',
      country: 'Italia'
    },
    insolvencyZone: null,
    credit: {
      amount: 1000,
      id: '1232',
      suggestedAmount: 20000,
      renewalDate: new Date(),
      status: CreditStatus.APPROVED,
      approvalLevels: [
        {
          threshold: 100000,
          title: 'Livello 1',
          roles: [
            'credit-expert'
          ],
          needsAttachment: false,
          state: {
            approvalDate: new Date(1606152831000),
            reason: '',
            user: {
              id: '7a6249e0-4181-42e4-a336-217de94563a6',
              emails: [
                'credit-expert@eight20.it'
              ],
              firstName: 'Credit',
              lastName: 'Expert',
              username: 'C.Expert'
            },
            credit: {
              id: '123231',
              suggestedAmount: 1000,
              amount: 60000,
              renewalDate: new Date(1607152831000),
              status: CreditStatus.APPROVED,
            },
          },
        },
        {
          threshold: 250000,
          title: 'Livello 2',
          roles: [
            'credit-manager'
          ],
          needsAttachment: false,
          state: null,
        },
        {
          threshold: 500000,
          title: 'Livello 3',
          roles: [
            'cfo'
          ],
          needsAttachment: false,
          state: null,
        },
        {
          threshold: 0,
          title: 'Livello 4',
          roles: [
            'credit-expert',
            'credit-manager',
            'cfo'
          ],
          needsAttachment: true,
          state: null,
        }
      ],
    },
    scoreCard: {
      id: '12312',
      name: 'unduetredodici srl',
      vatNumber: 'kLw20J2Jqsa21jqajwk',
      rulesetVersion: '1',
      score: 31,
      lastUpdate: new Date(1606151229000),
      variables: [
        {
          name: 'years_current_ownership',
          value: '0.0',
          score: 1,
          computed: '',
          origin: CustomerVariableOrigin.CREDIT_SAFE
        },
        {
          name: 'employees_count',
          value: '1.0',
          score: 4,
          computed: '',
          origin: CustomerVariableOrigin.CREDIT_SAFE
        },
        {
          name: 'score_credit_safe',
          value: '23.0',
          score: 0,
          computed: '',
          origin: CustomerVariableOrigin.CREDIT_SAFE
        },
        {
          name: 'legal_form',
          value: 'srl',
          score: 5,
          computed: '',
          origin: CustomerVariableOrigin.CREDIT_SAFE
        },
        {
          name: 'years_on_market',
          value: '9.0',
          score: 0,
          computed: '',
          origin: CustomerVariableOrigin.CREDIT_SAFE
        },
        {
          name: 'abc_commercial',
          value: 'D',
          score: 0,
          computed: '',
          origin: CustomerVariableOrigin.CREDIT_SAFE
        },
        {
          name: 'short_term_generational_change',
          value: 'false',
          score: 10,
          computed: '',
          origin: CustomerVariableOrigin.CREDIT_SAFE
        },
        {
          name: 'negative_events',
          value: 'false',
          score: 10,
          computed: '',
          origin: CustomerVariableOrigin.CREDIT_SAFE
        },
        {
          name: 'headquarter_area',
          value: 'north_west',
          score: 9,
          computed: '',
          origin: CustomerVariableOrigin.CREDIT_SAFE
        }
      ],
    },
    salesContact: {
      id: '1',
      emails: ['berd.losmen@creditscore.com', 'berd.losmen.work@creditscore.com'],
      firstName: 'Berd',
      lastName: 'Losmen',
      username: 'b.losmen',
      phoneNumbers: ['+399213309552', '+382103392774'],
    }
  }];

  insolvenciesChart: ChartItem[] = [
    {
     series: 'Insolvense',
      x: '2015',
      y: '10',
    },
    {
      series: 'Insolvense',
      x: '2016',
      y: '40',
    },
    {
      series: 'Insolvense',
      x: '2017',
      y: '80',
    },
    {
      series: 'Insolvense',
      x: '2018',
      y: '10',
    },
    {
      series: 'Insolvense',
      x: '2019',
      y: '14',
    },
    {
      series: 'Insolvense',
      x: '2020',
      y: '30',
    },
  ];

  scoreChart: ChartItem[] = [
    {
      series: 'Score',
      x: '2015',
      y: '20',
    },
    {
      series: 'Score',
      x: '2016',
      y: '50',
    },
    {
      series: 'Score',
      x: '2017',
      y: '21',
    },
    {
      series: 'Score',
      x: '2018',
      y: '78',
    },
    {
      series: 'Score',
      x: '2019',
      y: '43',
    },
    {
      series: 'Score',
      x: '2020',
      y: '31',
    },
  ];


  customer: Customer;

  constructor(@Optional() private apollo: Apollo) { }

  private static GetRequirementsInput({
                                        yearlyTargetRevenue,
                                        monthlyPeakRevenue,
                                        paymentTerms,
                                        paymentMethod
                                      }: CustomerRequirementsInput) {
    return `requirements: {
      yearlyTargetRevenue: ${yearlyTargetRevenue},
      monthlyPeakRevenue: ${monthlyPeakRevenue},
      paymentTerms: ${paymentTerms},
      paymentMethod: ${paymentMethod},
    }`;
  }

  private getCredit() {
    return `{
                id,
                amount,
                suggestedAmount,
                status,
                renewalDate,
                approvalLevels {
                  threshold
                  title
                  roles
                  needsAttachment
                  state{
                    ... on Approval {
                      approvalDate,
                      approvalReason: reason,
                      user {
                        id,
                        emails,
                        firstName,
                        lastName,
                      },
                      attachment {
                        uri,
                        name,
                        type,
                        size,
                      },
                      credit {
                        amount
                      },
                    },
                    ... on Rejection {
                      rejectionDate,
                      rejectionReason: reason,
                      user {
                        id,
                        emails,
                        firstName,
                        lastName,
                      },
                        credit {
                        amount
                      }
                    }
                  }
                }
              }`;
  }

  private getCustomerBody() {
    return `{
              id,
              name,
              classification,
              insolvencyZone,
              requirements {
                yearlyTargetRevenue,
                monthlyPeakRevenue,
                paymentTerms,
                paymentMethod
              },
              credit ${this.getCredit()},
              renewalCredit ${this.getCredit()}
              vatNumber,
              payerCode,
              status,
              attachments {
                uri,
                name,
                type,
                size,
              },
              address {
                street,
                city,
                province,
                postalCode
              },
              salesContact {
                id,
                emails,
                firstName,
                lastName,
                phoneNumbers
              },
              scoreCard {
                score,
                notGo,
                variables {
                  name,
                  value,
                  score,
                  computed,
                  origin,
                }
              },
          }`;
  }

  getRenewalList(filter = '',
                 offset = 0,
                 limit = 10,
                 viewAll = true,
                 classifications: Classification[] = [],
                 creditStatus: CreditStatus[] = [],
                 sortingFields: SortingField[] = [{field: Field.NAME, order: Order.ASC}]):
    Observable<ApolloQueryResult<{ getCustomerRenewalList: CustomerList }>> {

    /* return new Observable<ApolloQueryResult<{getCustomerList: CustomerList}>>(observer => {
     observer.next({
       data: {
         getCustomerList: {
           totalCount: 1,
           offset: 0,
           limit: 10,
           customers: this.customers
         },
       },
       loading: false,
       networkStatus: NetworkStatus.ready,
     });
     observer.complete();
   }); */
    return this.apollo.watchQuery<{ getCustomerRenewalList: CustomerList }>({
      query: gql`
        query customerList ($fields: [SortingField!]!, $classifications: [Classification!], $creditStatus: [CreditStatus!] ){
          getCustomerRenewalList (query: {
              filter: {
                text: "${filter}",
                offset: ${offset},
                limit: ${limit},
                viewAll: ${viewAll},
                classifications: $classifications,
                creditStatus: $creditStatus,
              },
              sorting: {
                fields: $fields
              }
            }) {
              totalCount,
              offset,
              limit,
              customers ${this.getCustomerBody()}
          }
        }
      `,
      variables: {
        fields: sortingFields,
        classifications,
        creditStatus,
      },
      errorPolicy: 'all'
    })
      .valueChanges.pipe(
        tap(res => {

          if (res.data && res.data.getCustomerRenewalList && res.data.getCustomerRenewalList.customers) {
            this.customers = res.data.getCustomerRenewalList.customers;
          } else {
            this.customers = [];
          }
        }),
        catchError((err, caught) => {
          // whatever you want to handle error
          if (err.graphQLErrors) {
            err.graphQLErrors.forEach(e => {
              console.log(e);
            });
          }
          if (err.networkError) {
            console.log(err);
          }
          // default return
          return caught;
        }));
  }

  getMonitoringList(filter = '',
                    offset = 0,
                    limit = 10,
                    viewAll = true,
                    classifications: Classification[] = [],
                    insolvencyZones: InsolvencyZone[] = [],
                    sortingFields: SortingField[] = [{field: Field.NAME, order: Order.ASC}]):
    Observable<ApolloQueryResult<{ getCustomerMonitoringList: CustomerList }>> {

     /* return new Observable<ApolloQueryResult<{getCustomerList: CustomerList}>>(observer => {
      observer.next({
        data: {
          getCustomerList: {
            totalCount: 1,
            offset: 0,
            limit: 10,
            customers: this.customers
          },
        },
        loading: false,
        networkStatus: NetworkStatus.ready,
      });
      observer.complete();
    }); */
    return this.apollo.watchQuery<{ getCustomerMonitoringList: CustomerList }>({
      query: gql`
        query customerList ($fields: [SortingField!]!, $classifications: [Classification!], $insolvencyZones: [InsolvencyZone!]){
          getCustomerMonitoringList (query: {
              filter: {
                text: "${filter}",
                offset: ${offset},
                limit: ${limit},
                viewAll: ${viewAll},
                insolvencyZones: $insolvencyZones,
                classifications: $classifications,
              },
              sorting: {
                fields: $fields
              }
            }) {
              totalCount,
              offset,
              limit,
              customers ${this.getCustomerBody()}
          }
        }
      `,
      variables: {
        fields: sortingFields,
        classifications,
        insolvencyZones,
      },
      errorPolicy: 'all'
    })
      .valueChanges.pipe(
        tap(res => {

          if (res.data && res.data.getCustomerMonitoringList && res.data.getCustomerMonitoringList.customers) {
            this.customers = res.data.getCustomerMonitoringList.customers;
          } else {
            this.customers = [];
          }
        }),
        catchError((err, caught) => {
          // whatever you want to handle error
          if (err.graphQLErrors) {
            err.graphQLErrors.forEach(e => {
              console.log(e);
            });
          }
          if (err.networkError) {
            console.log(err);
          }
          // default return
          return caught;
        }));
  }

  getById(id: string):
     Observable<ApolloQueryResult<{ getCustomerById: Customer }>> {
     /* return new Observable(observer => {
      observer.next({
        data: {
          getCustomerById: this.customers[0]
        },
        loading: false,
        networkStatus: 200,
      });

      observer.complete();
    });*/

    return this.apollo.watchQuery<{ getCustomerById: Customer }>({
      query: gql`
        query getCustomer {
          getCustomerById (customerId: ${id}) ${this.getCustomerBody()}
        }
      `
    })
      .valueChanges.pipe(tap(res => {

        if (res.data && res.data.getCustomerById) {
          this.customer = res.data.getCustomerById;
        } else {
          this.customer = undefined;
        }
      }));
  }

  addCustomer(customer: Customer) {
    this.customers.unshift(customer);
  }


  updateRequirements(vatNumber: string, requirementsInput: CustomerRequirementsInput, )
    : Observable<FetchResult<{ updateCustomerRequirements: Credit }>> {
    return this.apollo.mutate<{ updateCustomerRequirements: Credit }>({
      mutation: gql`
        mutation {
          updateCustomerRequirements(vatNumber: "${vatNumber}", ${CustomerService.GetRequirementsInput(requirementsInput)})
          {
            id
            amount
            suggestedAmount
            renewalDate
            approvalLevels {
              threshold
              title
              roles
              needsAttachment
              approvalState: state {
                ... on Approval {
                  approvalDate,
                  reason,
                  user {
                    id,
                    emails,
                    firstName,
                    lastName,
                  },
                  attachment {
                      uri,
                      name,
                      type,
                      size,
                    },
                  credit {
                    amount
                  },
                }
              }
              rejectionState: state {
                ... on Rejection {
                  rejectionDate,
                  reason,
                  user {
                    id,
                    emails,
                    firstName,
                    lastName,
                  },
                  credit {
                    amount
                  }
                }
              }
            }
            status
          }
        }
      `
    });
  }

  getCustomerInsolvenciesChartHistory(vatNumber: string, range: TimeRange):
    Observable<ApolloQueryResult<{getCustomerInsolvenciesChartHistory: ChartItem[]}>> {
    return this.apollo.watchQuery<{getCustomerInsolvenciesChartHistory: ChartItem[]}>({
      query: gql`
       query getCustomerInsolvenciesChartHistory {
        getCustomerInsolvenciesChartHistory (
          vatNumber: "${vatNumber}",
          range: {intervals: ${range.intervals}, period: ${range.period}}) {
          series,
          y,
          x
        }
       }
      `
    }).valueChanges;
    /*return new Observable(observer => {
      observer.next({
        data: {
          getCustomerInsolvenciesChartHistory: this.insolvenciesChart
        },
        loading: false,
        networkStatus: 200,
      });
      observer.complete();
    });*/
  }

  getCustomerScoreChartHistory(vatNumber: string, range: TimeRange)
    : Observable<ApolloQueryResult<{getCustomerScoreChartHistory: ChartItem[]}>> {
    return this.apollo.watchQuery<{getCustomerScoreChartHistory: ChartItem[]}>({
      query: gql`
       query getCustomerScoreChartHistory {
        getCustomerScoreChartHistory (
          vatNumber: "${vatNumber}",
          range: {intervals: ${range.intervals}, period: ${range.period}}) {
          series,
          y,
          x
        }
       }
      `
    }).valueChanges;

    /* return new Observable(observer => {
      observer.next({
        data: {
          getCustomerScoreChartHistory: this.scoreChart
        },
        loading: false,
        networkStatus: 200,
      });
      observer.complete();
    });*/
  }

  getAllCustomersRenewalsChartHistory(range: TimeRange)
    : Observable<ApolloQueryResult<{getAllCustomersRenewalsChartHistory: ChartItem[][]}>> {
    return this.apollo.watchQuery<{getAllCustomersRenewalsChartHistory: ChartItem[][]}>({
      query: gql`
       query getAllCustomersRenewalsChartHistory {
        getAllCustomersRenewalsChartHistory (
          range: {intervals: ${range.intervals}, period: ${range.period}}) {
          series,
          y,
          x
        }
       }
      `
    }).valueChanges;
  }

  getAllUnderwritingsStatesChartHistory(range: TimeRange)
    : Observable<ApolloQueryResult<{getAllUnderwritingsStatesChartHistory: ChartItem[][]}>> {
    return this.apollo.watchQuery<{getAllUnderwritingsStatesChartHistory: ChartItem[][]}>({
      query: gql`
       query getAllUnderwritingsStatesChartHistory {
        getAllUnderwritingsStatesChartHistory (
          range: {intervals: ${range.intervals}, period: ${range.period}}) {
          series,
          y,
          x
        }
       }
      `
    }).valueChanges;
  }

  getAllCustomersInsolvencyZones()
    : Observable<ApolloQueryResult<{getAllCustomersInsolvencyZones: ChartItem[][]}>> {
    return this.apollo.watchQuery<{getAllCustomersInsolvencyZones: ChartItem[][]}>({
      query: gql`
       query getAllCustomersInsolvencyZones {
        getAllCustomersInsolvencyZones {
          series,
          y,
          x
        }
       }
      `
    }).valueChanges;
  }

  getMassiveRenewalExecuting()
    : Observable<ApolloQueryResult<{getMassiveRenewalExecuting: MassiveRenewalExecution}>> {
    return this.apollo.watchQuery<{getMassiveRenewalExecuting: MassiveRenewalExecution}>({
      query: gql`
       query getMassiveRenewalExecuting {
        getMassiveRenewalExecuting {
          executing,
          massiveRenewalLevel,
          completionPercentage
        }
       }
      `
    }).valueChanges;
  }

  getMassiveRenewalProgress()
    : Observable<ApolloQueryResult<{getMassiveRenewalProgress: MassiveRenewalProgress}>> {
    return this.apollo.watchQuery<{getMassiveRenewalProgress: MassiveRenewalProgress}>({
      query: gql`
       query getMassiveRenewalProgress {
        getMassiveRenewalProgress {
          massiveRenewalLevel,
          massiveRenewalProgressStatus,
          startDate,
          endDate,
          total,
          scoreRecalculated,
          approved,
          nextLevelApprovalNeeded,
          skipped,
          scoreFailed
        }
       }
      `
    }).valueChanges;
  }

  startMassiveRenewal(massiveRenewalLevel: MassiveRenewalLevel): Observable<FetchResult<MutationEmptyResponse>> {
    return this.apollo.mutate<MutationEmptyResponse>({
      mutation: gql`
        mutation {
          startMassiveRenewal(
            massiveRenewalLevel: ${massiveRenewalLevel}
          ) {
          _
          }
        }
      `,
    });
  }

  getAllCustomersInsolvenciesChartHistory(range: TimeRange)
    : Observable<ApolloQueryResult<{getAllCustomersInsolvenciesChartHistory: ChartItem[]}>> {
    return this.apollo.watchQuery<{getAllCustomersInsolvenciesChartHistory: ChartItem[]}>({
      query: gql`
       query getAllCustomersInsolvenciesChartHistory {
        getAllCustomersInsolvenciesChartHistory (
          range: {intervals: ${range.intervals}, period: ${range.period}}) {
          series,
          y,
          x
        }
       }
      `
    }).valueChanges;
  }

  getCreditSafeReportsChart(range: TimeRange)
    : Observable<ApolloQueryResult<{getCreditSafeReportsChart: ChartItem[]}>> {
    return this.apollo.watchQuery<{getCreditSafeReportsChart: ChartItem[]}>({
      query: gql`
       query getCreditSafeReportsChart {
        getCreditSafeReportsChart (
          range: {intervals: ${range.intervals}, period: ${range.period}}) {
          series,
          y,
          x
        }
       }
      `
    }).valueChanges;
  }

  reject(rejection: RejectionInput): Observable<FetchResult<Credit>> {
    return this.apollo.mutate<Credit>({
      mutation: gql`
        mutation {
          rejectCustomerCredit(
            vatNumber: "${rejection.vatNumber}",
            rejection: {
              level: ${rejection.level},
              reason: "${rejection.reason}"
            }
          ) {
            id,
            amount,
            suggestedAmount,
            renewalDate,
            status,
          }
        }
      `
    });
  }

  approveCustomerCredit(data: ApprovalInput): Observable<FetchResult<Customer>> {
    return this.apollo.mutate<Customer>({
      mutation: gql`
        mutation approveCustomerCredit($reason: String, $attachment: CustomerAttachmentInput){
          approveCustomerCredit(
            vatNumber: "${data.vatNumber}",
            approval: {
              renewalDate: ${data.renewalDate.getTime()},
              credit: ${data.credit},
              level: ${data.level},
              reason: $reason,
              attachment: $attachment
            }
          ) {
            id,
            amount,
            suggestedAmount,
            renewalDate,
            status,
          }
        }
      `,
      variables: {
        reason: data.reason,
        attachment: data.attachment,
      },
    });
  }

  bulkApproveCustomerCredit(vatNumbers: string[]): Observable<FetchResult<BulkSummary>> {
    return this.apollo.mutate<BulkSummary>({
      mutation: gql`
        mutation bulkApproveCustomerCredit($vatNumbers: [String!]!){
          bulkApproveCustomerCredit(
            vatNumbers: $vatNumbers
          ) {
            failedCustomers {
              id,
              name,
              vatNumber,
            },
            successCustomers {
              id,
              name,
              vatNumber,
            }
          }
        }
      `,
      variables: {
        vatNumbers,
      },
    });
  }

  bulkRejectCustomerCredit(vatNumbers: string[]): Observable<FetchResult<BulkSummary>> {
    return this.apollo.mutate<BulkSummary>({
      mutation: gql`
        mutation bulkRejectCustomerCredit($vatNumbers: [String!]!){
          bulkRejectCustomerCredit(
            vatNumbers: $vatNumbers
          ) {
            failedCustomers {
              id,
              name,
              vatNumber,
            },
            successCustomers {
              id,
              name,
              vatNumber,
            }
          }
        }
      `,
      variables: {
        vatNumbers,
      },
    });
  }


  addCustomerAttachmentList(vatNumber: string, attachments: CustomerAttachmentInput[]):
    Observable<FetchResult<{addCustomerAttachmentList: CustomerAttachment[]}>> {
    return this.apollo.mutate<{addCustomerAttachmentList: CustomerAttachment[]}>({
      mutation: gql`
        mutation addCustomerAttachmentList($attachments: [CustomerAttachmentInput!]!){
          addCustomerAttachmentList(
            vatNumber: "${vatNumber}",
            attachments: $attachments
          ) {
            uri
            name
            type
            size
          }
        }
      `,
      variables: {
        attachments
      }
    });
  }

  deleteCustomerAttachmentList(vatNumber: string, attachments: CustomerAttachmentInput[]):
    Observable<FetchResult<{deleteCustomerAttachmentList: CustomerAttachment[]}>> {
    return this.apollo.mutate<{deleteCustomerAttachmentList: CustomerAttachment[]}>({
      mutation: gql`
        mutation deleteCustomerAttachmentList($attachments: [CustomerAttachmentInput!]!){
          deleteCustomerAttachmentList(
            vatNumber: "${vatNumber}",
            attachments: $attachments
          ) {
            uri
            name
            type
            size
          }
        }
      `,
      variables: {
        attachments
      }
    });
  }


  isRequirementsFilled(r: CustomerRequirements): boolean {
    if (r) {
      return !!(
        r.monthlyPeakRevenue &&
        r.paymentTerms &&
        r.yearlyTargetRevenue
      );
    }

    return false;
  }

  reactivateAsProspect(customer: Customer): Observable<FetchResult<MutationEmptyResponse>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation reactivateCustomerAsProspect {
          reactivateCustomerAsProspect(vatNumber: "${customer.vatNumber}") { _ }
        }`
    });
  }

  keepCustomerInactive(customer: Customer): Observable<FetchResult<MutationEmptyResponse>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation resetCustomerStatusAsInactive {
          resetCustomerStatusAsInactive(vatNumber: "${customer.vatNumber}") { _ }
        }`
    });
  }
}
