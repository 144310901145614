import {Subscription} from 'rxjs';
import {UserProfile} from '@continental/models/user';
import {UserService} from './../../../@continental/services/user.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ROLES_LABELS} from "../../../@continental/constants/roles.contants";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {

  userChange: Subscription;
  currentUser: UserProfile;


  constructor(private userService: UserService) {
  }

  get roleDisplayName() {
    if (this.currentUser.actualRole) {
      return ROLES_LABELS[this.currentUser.actualRole];
    }
    return '';
  }


  ngOnInit(): void {
    this.userChange = this.userService.onUserChanged.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  ngOnDestroy(): void {
    this.userChange.unsubscribe();
  }

  logout() {
    this.userService.logout();
  }

}
