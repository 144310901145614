<div class="main mb-20" *ngIf="isFileUploadVisible">
  <div class="content" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
    <app-file-uploader
      csv
      dropAreaText="Importa CSV"
      accept=".xlsx, .xls, .csv"
      [(startUpload)]="startUpload"
      (filesChange)="onFilesChange($event)"
      (uploadEnds)="onUploadEnds($event)"
      (infoClick)="onInfoClick()" compact></app-file-uploader>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="!startUpload" mat-flat-button (click)="startUpload = true;" color="primary" [disabled]="!files.length">Upload</button>
    </div>
  </div>
</div>
