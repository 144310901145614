import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpEvent, HttpResponse} from '@angular/common/http';
import {StorageService} from '../../../services/storage.service';
import {UploadResult} from '../../../models/storage';
import {ImportCsvProgress, ImportCsvResult, ImportCsvSummary} from '@creditscore/graphql-models/lib/graphql.types';
import {ErrorHandlerService} from '../../../services/error-handler.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit, OnChanges {

  @Input() accept = '*';
  @Input() startUpload = false;
  @Input() dropAreaText = 'Clicca o trascina qui il documento';
  @Input() multiple;
  @Input() compact;
  @Input() csv: boolean | string;
  @Output() uploadEnds: EventEmitter<UploadResult[]> =
    new EventEmitter<UploadResult[]>();
  @Input() files: File[] = [];
  @Output() filesChange: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() csvImportComplete: EventEmitter<ImportCsvSummary> = new EventEmitter<ImportCsvSummary>();
  @Output() startUploadChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() infoClick: EventEmitter<void> = new EventEmitter<void>();

  progress: number;

  progressCSV = -1;
  csvTotalRows: number;
  csvCurrentRow: number;
  sendableFormData: FormData;
  dragFiles: any;
  validComboDrag: any;
  lastInvalids: any;
  fileDropDisabled: any;
  maxSize: any;
  baseDropValid: any;
  lastFileAt: Date;

  httpEmitter: Subscription;
  httpEvent: HttpEvent<{}>;

  totalSuccesses = 0;
  totalErrors = 0;

  csvSummary: ImportCsvSummary;

  constructor(
    protected storageService: StorageService,
    protected  errorHandlerService: ErrorHandlerService,
    private cd: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.startUpload && changes.startUpload.currentValue) {
      this.uploadFiles().then(() => console.log('upload ok'));
      this.cd.detectChanges();
    }
  }

  ngOnInit(): void {
    this.multiple = (this.multiple === '' || this.multiple === true) ? 1 : 0;
    this.compact = (this.compact === '' || this.compact === true);
    this.csv = (this.csv === '' || this.csv === true);
  }

  getDate() {
    return new Date();
  }

  handleFiles(files: File[]): void {
    this.filesChange.emit(files);
    this.files = files;
  }

  async uploadFiles(): Promise<void> {
    const results: UploadResult[] = [];
    this.cd.detectChanges();
    for (const file of [...this.files]) {
      try {
        const result = await this.singleFileUpload(file);
        console.log('RESULT -> ', JSON.stringify(result));
        results.push(result);
        this.cd.detectChanges();
      } catch (e) {
        console.error(e);
        results.push(e);
        this.cd.detectChanges();
      }
    }
    this.progress = undefined;

    this.uploadEnds.emit(results);
  }

  singleFileUpload(file: File): Promise<UploadResult> {
    const myFormData: FormData = new FormData();
    myFormData.append('file', file, file.name);
    return new Promise<UploadResult>((resolve, reject) => {
      console.log(JSON.stringify(this.csv));
      if (this.csv) {
        console.log('Uploading csv');
        this.uploadCSV(file, myFormData, resolve, reject);
      } else {
        this.uploadFile(file, myFormData, resolve, reject);
      }
    });
  }

  uploadCSV(file, myFormData, resolve, reject) {
    const correlationId = this.storageService.generateCorrelationId();
    console.log('Correlation ID -> ', correlationId);
    this.storageService.importCSVStatus(correlationId).subscribe(res => {

      const status = res.data.importCsvStatus;
      console.log('csv status', status);

      if ('totalRows' in status) {
        const progress = status as ImportCsvProgress;
        this.csvTotalRows = progress.totalRows;
        this.csvCurrentRow = progress.currentRow;
        this.progressCSV = Math.floor((progress.successRows + progress.failedRows) / progress.totalRows * 100);

        this.totalSuccesses = progress.successRows;
        this.totalErrors = progress.failedRows;

      } else {
        const summary = status as ImportCsvSummary;
        this.csvSummary = summary;
        this.csvImportComplete.emit(summary);
        if (summary.result === ImportCsvResult.FAILED) {
          this.resetCSVUploader();
          this.errorHandlerService.show(
            'Importazione fallita',
            [],
            'Non è stato possibile importare il file. Controllare che l\'intestazione corrisponda a quanto supportato dall\'applicazione.');
        } else if (summary.result === ImportCsvResult.PARTIAL_IMPORT) {
          this.errorHandlerService.show(
            'Importazione parziale',
            [],
            'Non è stato possibile importare tutti i record. Controllare il contenuto del file.');
        }
      }

      this.cd.detectChanges();
    }, error => {
      this.errorHandlerService.show(
        'Impossibile importare il CSV',
        error.graphQLErrors || [],
        'Non è stato possibile importare il CSV. Contattare un amministratore di sistema.');
    });

    setTimeout(() => {
      this.httpEmitter = this.storageService.uploadCSV(correlationId, myFormData)
        .subscribe(
          event => {
            this.uploadStatus(file, event, resolve);
          },
          error => {
            this.showError(file, error, reject);
          }
        );
    }, 1000);

  }

  uploadFile(file, myFormData, resolve, reject) {
    this.httpEmitter = this.storageService.uploadFile(myFormData)
      .subscribe(
        event => {
          this.uploadStatus(file, event, resolve);
        },
        error => {
          this.showError(file, error, reject);
        }
      );
  }

  uploadStatus(file, event, resolve) {
    this.httpEvent = event;

    if (event instanceof HttpResponse) {
      delete this.httpEmitter;
      console.log('request done', event);
      resolve({attachment: event.body});
      this.removeQuee(file);
    }

    this.cd.detectChanges();
  }

  removeQuee(file) {
    const index = this.files.findIndex(f => f === file);
    if (index >= 0) {
      this.files.splice(index, 1);
    }
  }

  showError(file, error, reject) {
    alert('Error Uploading Files: ' + error.message);
    this.cd.detectChanges();
    delete this.httpEmitter;
    reject({
      error: {
        fileName: file.name,
        message: error.message
      }
    });
  }

  showInfo() {
    this.infoClick.emit();
    const errorMessage = this.csvSummary?.errors.reduce((p, c, i, a) => p + '<br>' + c);
    this.errorHandlerService.show('Errori CSV import', [], errorMessage, '90%');
  }

  resetCSVUploader() {

    this.totalSuccesses = 0;
    this.totalErrors = 0;
    this.progressCSV = -1;
    this.csvTotalRows = undefined;
    this.csvCurrentRow = undefined;
    this.startUpload = false;
    this.startUploadChange.emit(false);
  }

}
