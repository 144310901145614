export const CFO = 'cfo';
export const CREDIT_EXPERT = 'credit-expert';
export const CREDIT_MANAGER = 'credit-manager';
export const SALES_MANAGER = 'sales-manager';
export const SALES_USER = 'sales-user';

export const ROLES = [
  CFO,
  CREDIT_EXPERT,
  CREDIT_MANAGER,
  SALES_MANAGER,
  SALES_USER,
];

export const ROLES_LABELS = {
  [CFO]: 'CFO',
  [CREDIT_EXPERT]: 'Credit Professional',
  [CREDIT_MANAGER]: 'Credit Manager',
  [SALES_MANAGER]: 'Sales Manager',
  [SALES_USER]: 'Sales User',
};

export const UNDERWRITINGS_ROLES = [CFO, CREDIT_EXPERT, CREDIT_MANAGER];
