import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSolarComponent } from './loading-solar/loading-solar.component';



@NgModule({
  declarations: [LoadingSolarComponent],
  imports: [
    CommonModule,
  ],
  exports: [LoadingSolarComponent]
})
export class LoadingImageModule { }
