import {scoreLevels} from "../../@continental/components/score-elements/score.service";

export const locale = {
    lang: 'it',
    data: {
      FLEET: 'Flotta',
      WHOLESALE: 'Wholesale',
      RETAIL: 'Retail',
      OTHER: 'Altro',

      VIEW_ALL: {
        true: 'Sì',
        false: 'No',
      },

      CLASSIFICATION: {
        FLEET: 'Flotta',
        WHOLESALE: 'Wholesale',
        RETAIL: 'Retail',
        OTHER: 'Altro',
      },

      FILTERS: {
        VIEW_ALL: 'Vedi tutti',
        ZONE: 'Fascia',
        CLASSIFICATION: 'Tipo',
        INSOLVENCY_ZONE: 'Fascia',
        'UNDERWRITING.STATUS': 'Stato',
        'CUSTOMER.STATUS': 'Stato',
      },

      UNDERWRITING: {
        STATUS: {
          WAITING_APPROVAL: 'In attesa di approvazione',
          SAP_WAITING: 'In attesa di registrazione su SAP',
          REJECTED: 'Rifiutato'
        }
      },

      CUSTOMER: {
        STATUS: {
          SAP_REGISTERED: 'Registrato',
          LEGAL_DISPUTE: 'Disputa legale',
          INACTIVE: 'Inattivo',
          INACTIVE_UPDATE: 'Cliente inattivo con modifiche',
        }
      },

      SCORE_ORIGIN: {
        CREDIT_SAFE: 'Credit safe',
        CRM: 'CRM',
        SAP: 'SAP',
        OTHER: 'Altro'
      },

      IMPORT_CSV_RESULT: {
        OK: 'Ok',
        FAILED: 'Fallito',
        PARTIAL_IMPORT: 'Importato in parte'
      },

      INSOLVENCY_ZONE: {
        ZONE_0: 'Fascia: 0',
        ZONE_1: 'Fascia: 1',
        ZONE_2: 'Fascia: 2',
        ZONE_3: 'Fascia: 3',
        ZONE_4: 'Fascia: 4',
        ZONE_LEGAL: 'Azione legale',
        ZONE_INACTIVE: 'Inattivo',
        INACTIVE_UPDATE: 'Cliente inattivo con modifiche'
      },

      PAYMENT_METHOD: {
        RIBA: 'RIBA',
        SDD: 'SDD',
        BANK_TRANSFER: 'Rimessa diretta'
      },

      CREDIT_STATUS: {
        PROPOSED: 'Proposto',
        IN_APPROVAL: 'In approvazione',
        IN_PROGRESS: 'In corso',
        APPROVED: 'Approvato',
        EXPIRED: 'Scaduto',
        REJECTED: 'Rifiutato',
        RENEWED: 'Rinnovato'
      },

      SCORING: {
        STOP: 'Non affidabile'
      },

      PRESCORING: {
        STOP: 'Non apribile',
        BAD: 'Sconsigliato',
        MEDIUM: 'Da valutare',
        OK: 'Procedi',
        NP: 'Inattivo',
        NOT_FOUND: 'Non trovato'
      }
    },
};
