import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

function _window() {
  // return the global native browser window object
  return window as { [key: string]: any };
}

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: _window().env.sentryDSN,
  environment: _window().env.sentryEnv,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    /* new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', _window().env.sentryURL],
      routingInstrumentation: Sentry.routingInstrumentation,

    }), */
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
