import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import {ngfModule} from 'angular-file';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FlexLayoutModule} from '@angular/flex-layout';
import { FilesListComponent } from './files-list/files-list.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PipesModule} from '../../pipes/pipes.module';
import {CsvWidgetComponent} from './csv-widget/csv-widget.component';
import { ResultsDialog } from './results-dialog/results-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';



@NgModule({
  declarations: [FileUploaderComponent, FilesListComponent, CsvWidgetComponent, ResultsDialog],
  exports: [
    FileUploaderComponent,
    FilesListComponent,
    CsvWidgetComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,

    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,

    PipesModule,

    ngfModule,
    NgxDatatableModule,
  ]
})
export class StorageModule { }
