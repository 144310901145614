import {Injectable, Optional} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FetchResult, gql} from '@apollo/client/core';
import {ImportCsvStatus, ScoreCard, ScoreCardComputeError} from '@creditscore/graphql-models/lib/graphql.types';
import {Apollo} from 'apollo-angular';
import { v4 as uuidv4 } from 'uuid';


function _window() {
  // return the global native browser window object
  return window as { [key: string]: any };
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  uploadUrl = _window().env.gatewayRESTUrl + '/blob/upload'; // csv/import + correlationID
  uploadCSVUrl = _window().env.gatewayRESTUrl + '/csv/import';

  constructor(protected http: HttpClient, @Optional() private apollo: Apollo) {
  }

  generateCorrelationId() {
    return uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
  }


  uploadFile(formData: FormData, containerName?: string, uploadUrl = this.uploadUrl, correlationId?: string): Observable<HttpEvent<any>> {
    if (correlationId) {
      formData.append('containerName', containerName);
    }
    if (correlationId) {
      formData.append('correlationId', correlationId);
    }

    console.log('Upload url: ', uploadUrl);
    const req = new HttpRequest<FormData>(
      'POST',
      uploadUrl,
      formData, {
        reportProgress: true,
        responseType: 'json',
      },
    );

    return this.http.request(req);
  }

  uploadCSV(correlationId: string, formData: FormData, containerName?: string): Observable<HttpEvent<any>> {
    return this.uploadFile(formData, containerName, this.uploadCSVUrl, correlationId);
  }

  importCSVStatus(correlationId: string): Observable<FetchResult<{ importCsvStatus: ImportCsvStatus}>> {
    return this.apollo.subscribe<{ importCsvStatus: ImportCsvStatus }>({
      query: gql`
        subscription importCsvStatus{
          importCsvStatus(correlationId:"${correlationId}") {
             ...on ImportCsvProgress {
                failedRows
                successRows
                totalRows
                currentRow
                errorReason
            }
            ...on ImportCsvSummary {
              result
              errors
            }
          }
        }
      `
    });
  }

  download(url: string, filename: string): Promise<boolean | HttpErrorResponse>{
    return new Promise<boolean>((resolve, reject) => {
      this.http.get(url, {responseType: 'blob' as 'json'})
        .subscribe(
        (response: any) => {
          this.downloadFile(response, filename);
          resolve(true);
        }, error => {
          reject(error);
        }
      );
    });
  }

  downloadFile(response: any, filename: string) {
    const dataType = response.type;
    const binaryData = [];
    binaryData.push(response);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    if (filename) {
      downloadLink.setAttribute('download', filename);
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
