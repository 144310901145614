export const locale = {
    lang: 'en',
    data: {
      FLEET: 'Fleet',
      WHOLESALE: 'Wholesale',
      RETAIL: 'Retail',
      OTHER: 'Other',

      VIEW_ALL: {
        true: 'Sì',
        false: 'No',
      },

      CLASSIFICATION: {
        FLEET: 'Fleet',
        WHOLESALE: 'Wholesale',
        RETAIL: 'Retail',
        OTHER: 'Other',
      },

      FILTERS: {
        VIEW_ALL: 'Vedi tutti',
        ZONE: 'Fascia',
        CLASSIFICATION: 'Tipo',
        'UNDERWRITING.STATUS': 'Stato',
        'CUSTOMER.STATUS': 'Stato',
      },

      UNDERWRITING: {
        STATUS: {
          WAITING_APPROVAL: 'Waiting approval',
          SAP_WAITING: 'Waiting for insertion in SAP',
          REJECTED: 'Rejected'
        }
      },

      CUSTOMER: {
        STATUS: {
          SAP_REGISTERED: 'SAP Registered',
          LEGAL_DISPUTE: 'Legal dispute',
          INACTIVE: 'Inactive',
          INACTIVE_UPDATE: 'Inactive with csv updates',
        }
      },

      SCORE_ORIGIN: {
        CREDIT_SAFE: 'Credit safe',
        CRM: 'CRM',
        SAP: 'SAP',
        OTHER: 'Other'
      },

      IMPORT_CSV_RESULT: {
        OK: 'Ok',
        FAILED: 'Failed',
        PARTIAL_IMPORT: 'Partial import'
      },

      INSOLVENCY_ZONE: {
        ZONE_0: 'Zone: 0',
        ZONE_1: 'Zone: 1',
        ZONE_2: 'Zone: 2',
        ZONE_3: 'Zone: 3',
        ZONE_4: 'Zone: 4',
        ZONE_LEGAL: 'Legal',
        ZONE_INACTIVE: 'Inactive',
        INACTIVE_UPDATE: 'Inactive with csv updates'
      },

      PAYMENT_METHOD: {
        RIBA: 'RIBA',
        SDD: 'SDD',
        BANK_TRANSFER: 'Bank transfer'
      },

      CREDIT_STATUS: {
        PROPOSED: 'PROPOSED',
        IN_APPROVAL: 'IN_APPROVAL',
        IN_PROGRESS: 'IN_PROGRESS',
        APPROVED: 'APPROVED',
        EXPIRED: 'EXPIRED',
        REJECTED: 'REJECTED',
        RENEWED: 'RENEWED'
      },

      SCORING: {
        STOP: 'Unreliable'
      },

      PRESCORING: {
        STOP: 'Not openable',
        BAD: 'Not recommended',
        MEDIUM: 'To be evaluated',
        OK: 'Go on',
        NP: 'Inactive',
        NOT_FOUND: 'Not found'
      }
    }
};
