import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CustomerAttachment} from '@creditscore/graphql-models';
import {ColumnMode, SelectionType} from '@swimlane/ngx-datatable';
import {StorageService} from '../../../services/storage.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss']
})
export class FilesListComponent implements OnChanges {

  @Input() files: CustomerAttachment[];
  @Output() deleteRequest: EventEmitter<CustomerAttachment[]> = new EventEmitter<CustomerAttachment[]>();

  downloadingCount = 0;

  ColumnMode = ColumnMode;
  selected = [];
  SelectionType = SelectionType;

  rows = [];
  columns = [
    {name: 'name'},
    {name: 'size'},
    {name: 'type'},
    {name: 'action'}
  ];

  constructor(private storageService: StorageService, private cd: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.files) {
      this.files = changes.files.currentValue;
      this.parseElements();
    }
  }

  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  parseElements() {
    this.rows = [];
    if (!this.files) {
      return;
    }

    for (const file of this.files) {
      this.rows.push({
        name: file.name,
        size: file.size,
        type: file.type,
        action: {file, isDownloading: false},
      });
    }
  }

  download(fileAction: {file: CustomerAttachment, isDownloading: boolean}, index: number) {
    fileAction.isDownloading = true;
    this.downloadingCount++;
    this.cd.detectChanges();
    this.storageService.download(fileAction.file.uri, fileAction.file.name)
      .finally(() => {
        fileAction.isDownloading = false;
        this.downloadingCount--;
        this.cd.detectChanges();
      });
  }

  remove() {
    const attachments = this.selected.map(s => this.parseCustomerAttachment(s.action.file));
    this.deleteRequest.emit(attachments);
  }

  parseCustomerAttachment({uri, name, type, size}): CustomerAttachment {
    return {
      uri,
      type,
      size,
      name,
    };
  }

}
