import { UserService } from '@continental/services/user.service';
import { ProspectService } from '@continental/services/prospect.service';
import { ExcelService } from '@continental/services/excel.service';
import { DialogsModule } from '@continental/components/dialogs/dialogs.module';
import { LoadingService } from '@continental/services/loading.service';
import { Routes, RouterModule } from '@angular/router';
import { LayoutModule } from './layout/layout.module';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, LOCALE_ID, ErrorHandler} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {KeycloakInitializerFactory} from './app.keycloak';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql.module';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from '@angular/common/http';
import { TranslateModule, TranslateCompiler } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import localeIt from '@angular/common/locales/it';
import localeEnUs from '@angular/common/locales/en-US-POSIX';
import { registerLocaleData } from '@angular/common';

import * as Sentry from '@sentry/angular';
import {TokenInterceptor} from './token.interceptor';

const routes: Routes = [
  {
    path: 'summary',
    loadChildren: () => import ('./main/summary/summary.module').then(m => m.SummaryModule)
  },
  {
    path: 'prospect',
    loadChildren: () => import ('./main/prospect/prospect.module').then(m => m.ProspectModule)
  },
  {
    path: 'underwriting',
    loadChildren: () => import ('./main/underwriting/underwriting.module').then(m => m.UnderwritingModule)
  },
  {
    path: 'clients',
    loadChildren: () => import ('./main/clients/clients.module').then(m => m.ClientsModule)
  },
  {
    path: 'monitoring',
    loadChildren: () => import ('./main/monitoring/monitoring.module').then(m => m.MonitoringModule)
  },
  {
    path: '**',
    loadChildren: () => import ('./main/summary/summary.module').then(m => m.SummaryModule)
  }
];

registerLocaleData(localeIt, 'it');
registerLocaleData(localeEnUs, 'en');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    KeycloakAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DialogsModule,

    RouterModule.forRoot(routes),
    TranslateModule.forRoot(),

    LayoutModule,

    HammerModule,

    GraphQLModule,
    HttpClientModule,

    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      }
    }),
  ],
  providers: [
     {
      provide: APP_INITIALIZER,
      useFactory: KeycloakInitializerFactory,
      deps: [KeycloakService, UserService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: LOCALE_ID,
      useValue: 'it-IT' // 'de-DE' for Germany, 'fr-FR' for France ...
    },
    UserService,
    LoadingService,
    ProspectService,
    ExcelService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
