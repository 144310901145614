
<div [ngClass]="{'compact': compact}">
  <ng-container *ngIf="!files.length && progressCSV < 0">
    <div
      ngfDrop
      [multiple]         = "multiple"
      selectable       = "1"
      [(validDrag)]    = "validComboDrag"
      [files]          = "files"
      (filesChange)     = "lastFileAt=getDate(); handleFiles($event)"
      [accept]           = "accept"
      [maxSize]        = "maxSize"
      [(lastInvalids)] = "lastInvalids"
      [(dragFiles)]    = "dragFiles"
      class            = "well my-drop-zone"
      [class.invalid-drag] = "validComboDrag===false"
      [class.valid-drag]   = "validComboDrag"
      [fileDropDisabled] = "fileDropDisabled"
    >
      <div [innerHTML]="dropAreaText"></div>
    </div>
  </ng-container>

  <ngfFormData
    [files]      = "files"
    postName     = "file"
    [(FormData)] = "sendableFormData"
  ></ngfFormData>
  <ngfUploadStatus
    [(percent)] = "progress"
    [httpEvent] = "httpEvent"
  ></ngfUploadStatus>

  <div class="well my-drop-zone" *ngIf="files.length && progressCSV < 0">
    <div *ngFor="let item of files;let i=index" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="8px" class="py-8">
      <div>
        <mat-icon>insert_drive_file</mat-icon>
      </div>
      <div>
        <!--div *ngIf="['image/gif','image/png','image/jpeg'].indexOf(item.type)>=0">
          <div class="previewIcon" [ngfBackground]="item"></div>
        </div-->
        <strong>{{ (item.name.length > 16)? (item.name | slice:0:16)+'...' : (item.name) }}</strong>
      </div>
      <div *ngIf="!compact">
        {{ item.type }}
      </div>
      <div *ngIf="!compact">
        {{ item.size/1024/1024 | number:'.2' }} MB
      </div>
      <div>
        <button mat-icon-button color="warn"
                (click)="files.splice(i,1)"
        >
          <mat-icon >remove_circle_outline</mat-icon>
        </button>
      </div>
    </div>

    <mat-progress-bar
      *ngIf="progress && startUpload"
      class="mt-20"
      mode="determinate"
      [value]="progress"
      color="primary">
    </mat-progress-bar>

  </div>

  <div class="csv-progress" *ngIf="progressCSV >= 0"
       fxLayout="row"
       fxLayoutAlign="center center"
       fxLayoutGap="8px">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="8px">
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="12px">
        <div>CVS import: </div>
        <mat-progress-bar
          mode="determinate"
          [value]="progressCSV"
          color="primary">
        </mat-progress-bar>
        <div>{{csvCurrentRow}} / {{csvTotalRows}}</div>
        <mat-icon class="good" *ngIf="progressCSV == 100">check_circle</mat-icon>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="12px"
        class="details">
        <div>Ok: <span class="result-text good">{{totalSuccesses}}</span></div>
        <div>Errori: <span class="result-text bad">{{totalErrors}}</span></div>
      </div>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="8px">
      <button mat-flat-button (click)="resetCSVUploader()" color="primary">Chiudi</button>
      <button *ngIf="totalErrors" mat-flat-button (click)="showInfo()">Info</button>
    </div>
  </div>
</div>

