import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WShelperService {

  private wsConnection$: BehaviorSubject<boolean> = new BehaviorSubject(false);


  setWsConnectionState(state: boolean) {
    this.wsConnection$.next(state);
  }

  getWsConnectionState(): Observable<boolean> {
    return this.wsConnection$.asObservable();
  }

}
