import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {UserProfile} from '@continental/models/user';
import {UserService} from '@continental/services/user.service';
import {Subscription} from 'rxjs';
import {CFO, CREDIT_EXPERT, CREDIT_MANAGER} from '@continental/constants/roles.contants';
import {UploadResult} from '@continental/models/storage';
import {ErrorHandlerService} from '../../../services/error-handler.service';

@Component({
  selector: 'app-csv-widget',
  templateUrl: './csv-widget.component.html',
  styleUrls: ['./csv-widget.component.scss']
})
export class CsvWidgetComponent implements OnInit, OnDestroy {

  currentUser: UserProfile;
  startUpload = false;
  files: File[] = [];

  results: UploadResult[] = [];

  private subs: Subscription[] = [];

  constructor(
    private userService: UserService,
    private errorService: ErrorHandlerService
  ) {
  }

  ngOnInit(): void {
    this.subs.push(this.userService.onUserChanged.subscribe(user => this.currentUser = user));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub && sub.unsubscribe());
  }

  get isFileUploadVisible(): boolean {
    return [CREDIT_EXPERT, CREDIT_MANAGER, CFO].indexOf(this.currentUser?.actualRole) >= 0;
  }

  onFilesChange(files: File[]): void {
    this.files = files;
    console.log(files);
  }

  onUploadEnds(results: UploadResult[]): void {
    console.log('OnUploadEnds: ', results);
    // if (results.)
  }

  onInfoClick() {
    // this
  }

}
