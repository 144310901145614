import { slideInAnimation } from './animations';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import moment from 'moment';
import 'moment/locale/it';
import {TranslateService} from '@ngx-translate/core';

import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation,
  ]
})
export class AppComponent implements OnInit {
  title = 'creditscore';

  constructor(private translate: TranslateService) {
    translate.addLangs(['en', 'it']);
    translate.setTranslation('it', italian.data, true);
    translate.setTranslation('en', english.data, true);
    translate.setDefaultLang('it');
    translate.use('it');
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  ngOnInit() {
    moment.locale('it');
    registerLocaleData(localeIt, 'it-IT');
  }
}
