import { ROLES } from '@continental/constants/roles.contants';
import { UserProfile } from '@continental/models/user';
import { UserService } from '@continental/services/user.service';
import {KeycloakService} from 'keycloak-angular';

function _window() {
  // return the global native browser window object
  return window as { [key: string]: any };
}

export function KeycloakInitializerFactory(keycloak: KeycloakService, userService: UserService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: {
            url: _window().env.keycloakUrl,
            realm: _window().env.keycloakRealm,
            clientId: _window().env.keycloakClientId
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false,
            // flow: _window().env.keycloakFlow ? _window().env.keycloakFlow : 'implicit';
          },
          loadUserProfileAtStartUp: true,
          enableBearerInterceptor: true,
          bearerPrefix: 'Bearer',
          bearerExcludedUrls: []
        });

        const currentUser: UserProfile = await keycloak.loadUserProfile();
        currentUser.roles = keycloak.getUserRoles();

        if (currentUser.roles) {
          const validRoles = currentUser.roles.filter(r => ROLES.includes(r));
          if (validRoles.length === 1) {
            currentUser.actualRole = validRoles[0];
          }
        }
        userService.currentUser = currentUser;

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}
