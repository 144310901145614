import { ErrorDialog } from './../components/dialogs/error-dialog/error-dialog.component';
import { Injectable, Optional } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GraphQLError } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  dialogRef: MatDialogRef<any>;

  constructor(@Optional() public dialog: MatDialog) {}

  show(title: string, errors: readonly GraphQLError[], message = '', width = '320px'): void {

    for (const error of errors) {
      message += error.message + '<br>';
    }

    this.dialogRef = this.dialog.open(ErrorDialog, {
      width,
      disableClose: false,
      data: { title, message},
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  hide() {
    setTimeout(() => {
      this.dialogRef.close();
    }, 0);
  }
}
