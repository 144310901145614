import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import gql from 'graphql-tag';
import { User, Prospect } from '@creditscore/graphql-models';
import { Apollo } from 'apollo-angular';
import { Injectable, Optional } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { UserProfile } from '@continental/models/user';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _currentUserProfile: UserProfile;

  onUserChanged: BehaviorSubject<any> = new BehaviorSubject(undefined);

  users: User[];
  prospect: Prospect;

  constructor(@Optional() private apollo: Apollo, private keycloakService: KeycloakService) {}

  set currentUser(userProfile: UserProfile) {
    this._currentUserProfile = userProfile;
    this.onUserChanged.next(this.currentUser);
  }

  get currentUser(): UserProfile {
    return this._currentUserProfile;
  }

  logout() {
    this.keycloakService.logout();
    this.currentUser = undefined;
    this.onUserChanged.next(undefined);
  }

  /*getUserList(filter = '', offset = 0, limit = 10):
     /*Observable<ApolloQueryResult<{getList: ProspectList}> {
    return this.apollo.watchQuery<{getList: ProspectList}>({
      query: gql`
        query prospectList {
          getList (filter: {text: "${filter}", offset: ${offset}, limit: ${limit}}) {
            totalCount,
            offset,
            limit,
            prospects {
              id,
              name,
              logo,
              vatNumber,
              address {
                street,
                city,
                province,
                postalCode
              },
              salesContact {
                id,
                emailAddresses {
                  email,
                  type
                },
                firstName,
                lastName,
                phoneNumbers {
                  number,
                  type
                }
              }
            }
          }
        }
      `
    })
    .valueChanges.pipe(tap(res => {
      if (res.data && res.data.getList && res.data.getList.prospects) {
        this.prospects = res.data.getList.prospects;
      } else {
        this.prospects = [];
      }
    }));
  }

  getUserById(id: string):
    Observable<ApolloQueryResult<{getById: Prospect}>> {
    return this.apollo.watchQuery<{getById: Prospect}>({
      query: gql`
        query getProspect {
          getById (prospectId: ${id}) {
              id,
              name,
              logo,
              vatNumber,
              address {
                street,
                city,
                province,
                postalCode
              },
              salesContact {
                id,
                emailAddresses {
                  email,
                  type
                },
                firstName,
                lastName,
                phoneNumbers {
                  number,
                  type
                }
              },
              scoreFields {
                name,
                value,
                score
              },
              requirements {
                yearlyTargetRevenue,
                monthlyPeakRevenue,
                paymentTerms,
                paymentMethod
              }
          }
        }
      `
    })
    .valueChanges.pipe(tap(res => {
      if (res.data && res.data.getById) {
        this.prospect = res.data.getById;
      } else {
        this.prospects = undefined;
      }
    }));
  }

  promoteToUnderwriting(vatNumber: string): Observable<FetchResult<Underwriting>> {
    return this.apollo.mutate<Underwriting>({
      mutation: gql`
        mutation {
          createUnderwriting(vatNumber: "${vatNumber}") {
            id,
            name,
          }
        }
      `
    });
  }*/
}
