import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import gql from 'graphql-tag';
import {Credit, Field, Order, Prospect, ProspectList, SortingField, Underwriting,} from '@creditscore/graphql-models';
import {Apollo} from 'apollo-angular';
import {Injectable, Optional} from '@angular/core';
import {ApolloQueryResult, FetchResult} from '@apollo/client/core';
import {Classification, CustomerRequirementsInput} from '@creditscore/graphql-models/lib/graphql.types';
import {CustomerService} from './customer.service';
import {UnderwritingService} from './underwriting.service';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {

  prospects: Prospect[];
  prospect: Prospect;

  constructor(
    @Optional() private apollo: Apollo,
    protected customerService: CustomerService,
    protected underwritingService: UnderwritingService) {
  }

  private static GetProspectBody() {
    return `{
      id,
      name,
      vatNumber,
      classification,
      credit {
        id,
        amount,
        suggestedAmount,
        status
      }
      salesContact {
        id,
        username,
        firstName,
        lastName,
        emails,
        phoneNumbers,
      },
      address {
        street,
        city,
        province,
        postalCode,
        country
      },
      requirements {
        yearlyTargetRevenue,
         monthlyPeakRevenue,
          paymentTerms,
        paymentMethod
      },
      scoreCard {
        id,
        score,
        notGo,
        lastUpdate,
        variables {
          name,
          value,
          score,
          computed,
          origin,
        }
    }
  }`;
  }

  getList(filter = '',
          offset = 0,
          limit = 10,
          viewAll = true,
          classifications: Classification[] = [],
          sortingFields: SortingField[] = [{field: Field.NAME, order: Order.ASC}]):
    Observable<ApolloQueryResult<{ getProspectList: ProspectList }>> {
    console.log('Requesting classifications', classifications);
    return this.apollo.watchQuery<{ getProspectList: ProspectList }>({
      query: gql`query prospectList ($fields: [SortingField!]!, $classifications: [Classification!]) {
        getProspectList (query: {
          filter: {
            text: "${filter}",
            offset: ${offset},
            limit: ${limit},
            viewAll: ${viewAll},
            classifications: $classifications
          },
          sorting: {
            fields: $fields,
          }
        }) {
          totalCount,
          offset,
          limit,
          prospects ${ProspectService.GetProspectBody()}
        }
      }`,
      variables: {
        fields: sortingFields,
        classifications
      }
    })
    .valueChanges.pipe(tap(res => {
      if (res.data && res.data.getProspectList && res.data.getProspectList.prospects) {
        this.prospects = res.data.getProspectList.prospects;
      } else {
        this.prospects = [];
      }
    }));
  }

  getById(id: string):
    Observable<ApolloQueryResult<{ getProspectById: Prospect }>> {
    return this.apollo.watchQuery<{ getProspectById: Prospect }>({
      query: gql`
        query getProspect {
          getProspectById (prospectId: ${id})
          ${ProspectService.GetProspectBody()}
        }
      `
    })
    .valueChanges.pipe(tap(res => {
      this.prospect = res?.data?.getProspectById;
    }));
  }

  updateRequirements(vatNumber: string, requirementsInput: CustomerRequirementsInput)
    : Observable<FetchResult<{ updateCustomerRequirements: Credit }>> {
    return this.customerService.updateRequirements(vatNumber, requirementsInput);
  }


  promote(vatNumber: string): Observable<FetchResult<{ createUnderwriting: Underwriting }>> {
    return this.underwritingService.create(vatNumber);
  }
}
